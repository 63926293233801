// Generated by Framer (89417f6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {UZp5A6r3B: {hover: true}};

const cycleOrder = ["UZp5A6r3B"];

const variantClassNames = {UZp5A6r3B: "framer-v-3gueym"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, width, ...props}) => { return {...props, eKu1jHxO9: image ?? props.eKu1jHxO9 ?? {src: "https://framerusercontent.com/images/FQavNbj7YQyDy0CtmUPRICdduI.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/FQavNbj7YQyDy0CtmUPRICdduI.png?scale-down-to=512 512w, https://framerusercontent.com/images/FQavNbj7YQyDy0CtmUPRICdduI.png 521w"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, eKu1jHxO9, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "UZp5A6r3B", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-3PHMv", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 430, intrinsicWidth: 521, pixelHeight: 430, pixelWidth: 521, sizes: "min(73px, 100vw)", ...toResponsiveImage(eKu1jHxO9)}} className={cx("framer-3gueym", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"UZp5A6r3B"} ref={ref} style={{filter: "drop-shadow(0px 10px 7px rgb(0, 0, 0))", WebkitFilter: "drop-shadow(0px 10px 7px rgb(0, 0, 0))", ...style}} {...addPropertyOverrides({"UZp5A6r3B-hover": {"data-framer-name": undefined, background: {alt: "", fit: "fill", intrinsicHeight: 430, intrinsicWidth: 521, pixelHeight: 430, pixelWidth: 521, sizes: "min(100px, 100vw)", ...toResponsiveImage(eKu1jHxO9)}}}, baseVariant, gestureVariant)}/></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-3PHMv [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-3PHMv .framer-14elsej { display: block; }", ".framer-3PHMv .framer-3gueym { height: 60px; position: relative; width: 73px; }", ".framer-3PHMv .framer-v-3gueym .framer-3gueym { cursor: pointer; }", ".framer-3PHMv.framer-v-3gueym.hover .framer-3gueym { aspect-ratio: 1.2166666666666666 / 1; height: var(--framer-aspect-ratio-supported, 82px); width: 100px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 60
 * @framerIntrinsicWidth 73
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"gHXxbdIEx":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"eKu1jHxO9":"image"}
 * @framerImmutableVariables false
 */
const FramerpfB6IuSFP: React.ComponentType<Props> = withCSS(Component, css, "framer-3PHMv") as typeof Component;
export default FramerpfB6IuSFP;

FramerpfB6IuSFP.displayName = "pc c";

FramerpfB6IuSFP.defaultProps = {height: 60, width: 73};

addPropertyControls(FramerpfB6IuSFP, {eKu1jHxO9: {__defaultAssetReference: "data:framer/asset-reference,FQavNbj7YQyDy0CtmUPRICdduI.png?originalFilename=86f2598286e50e27d28ab8a4ffb392e5.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerpfB6IuSFP, [])